import * as types from "./types";
import {
  getClaims,
  getUserClaims,
  createClaim,
  deleteClaim,
  getShiftClaim,
  fixShiftClaim,
  changeClaimStatus,
  addClaimComment,
  deleteClaimComment,
} from "./services";

export async function getClaimsAction({ commit }) {
  commit(types.PROCESSING_CLAIMS, true);

  let state = {
    success: false,
    message: null,
    data: null,
  };

  try {
    const { data } = await getClaims();
    commit(types.GET_CLAIMS_DATA, data);
    state.success = true;
    state.data = data;
  } catch (e) {
    console.error(e);
    state.message = e.error;
  } finally {
    commit(types.PROCESSING_CLAIMS, false);
  }

  return state;
}

export async function getUserClaimsAction({ commit }, payload) {
  commit(types.PROCESSING_CLAIMS, true);

  let state = {
    success: false,
    message: null,
    data: null,
  };

  try {
    const { data } = await getUserClaims(payload);
    commit(types.GET_CLAIMS_DATA, data);
    state.success = true;
    state.data = data;
  } catch (e) {
    console.error(e);
  } finally {
    commit(types.PROCESSING_CLAIMS, false);
  }

  return state;
}

export async function getShiftClaimAction({ commit }, payload) {
  commit(types.PROCESSING_CLAIMS, true);

  let state = {
    success: false,
    message: null,
    data: null,
  };

  try {
    const { data } = await getShiftClaim(payload);
    commit(types.GET_CLAIMS_DATA, data);
    state.success = true;
    state.data = data;
  } catch (e) {
    console.error(e);
  } finally {
    commit(types.PROCESSING_CLAIMS, false);
  }

  return state;
}

export async function createClaimAction({ commit }, payload) {
  commit(types.PROCESSING_CLAIMS, true);

  let state = {
    success: false,
    message: null,
    data: null,
  };
  try {
    const { data } = await createClaim(payload);
    commit(types.CREATE_CLAIM, data);
    state.success = true;
    state.data = data;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_CLAIMS, false);
  }

  return state;
}

export async function deleteClaimAction({ commit }, payload) {
  commit(types.PROCESSING_CLAIMS, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await deleteClaim(payload);
    commit(types.DELETE_CLAIM, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_CLAIMS, false);
  }

  return state;
}

export async function fixShiftClaimAction({ commit }, payload) {
  commit(types.PROCESSING_CLAIMS, true);

  let state = {
    success: false,
    message: null,
  };

  try {
    const { data } = await fixShiftClaim(payload);
    commit(types.GET_CLAIMS_DATA, data);
    state.success = true;
  } catch (e) {
    console.error(e);
  } finally {
    commit(types.PROCESSING_CLAIMS, false);
  }

  return state;
}

export async function changeClaimStatusAction({ commit }, payload) {
  commit(types.PROCESSING_CLAIMS, true);

  let state = {
    success: false,
    message: null,
  };

  try {
    const { data } = await changeClaimStatus(payload);
    commit(types.GET_CLAIMS_DATA, data);
    state.success = true;
  } catch (e) {
    console.error(e);
  } finally {
    commit(types.PROCESSING_CLAIMS, false);
  }

  return state;
}

export async function addClaimCommentAction({ commit }, payload) {
  commit(types.PROCESSING_CLAIMS, true);

  let state = {
    success: false,
    message: null,
  };

  try {
    await addClaimComment(payload);
    state.success = true;
  } catch (e) {
    console.error(e);
  } finally {
    commit(types.PROCESSING_CLAIMS, false);
  }

  return state;
}

export async function deleteClaimCommentAction({ commit }, payload) {
  commit(types.PROCESSING_CLAIMS, true);

  let state = {
    success: false,
    message: null,
  };

  try {
    await deleteClaimComment(payload);
    state.success = true;
  } catch (e) {
    console.error(e);
  } finally {
    commit(types.PROCESSING_CLAIMS, false);
  }

  return state;
}
