<template>
  <v-app-bar app color="primary" dark dense fixed>
    <v-icon class="pr-1" large>mdi-truck-fast-outline</v-icon>
    <v-toolbar-title>{{ name ?? "TwojaPraca" }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn
      v-if="isAuthenticated"
      color="pink darken-4"
      :to="{ path: '/logout' }"
    >
      <v-icon small>mdi-logout</v-icon>
      <span class="menu">Wyloguj</span>
    </v-btn>
    <v-btn v-else color="pink darken-4" :to="{ path: '/login' }">
      <v-icon small>mdi-login</v-icon>
      <span class="menu">Zaloguj się</span>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NavigationBar",

  computed: {
    ...mapGetters("authModule", {
      isAuthenticated: "isAuthenticated",
      name: "name",
    }),
  },
};
</script>
