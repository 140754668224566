import api from "@/api/api-config";

export async function getUsers() {
  return await api.get(`user`);
}

export async function getUser(userId) {
  return await api.get(`user/${userId}`);
}

export async function createUser(data) {
  return await api.post(`user/single`, data);
}

export async function updateUser(data) {
  return await api.put(`user`, data);
}

export async function deleteUser(userId) {
  return await api.delete(`user/${userId}`);
}
