import api from "@/api/api-config";

export async function getCollection() {
  return await api.get(`driver-late`);
}

export async function getByDriver(id) {
  return await api.get(`driver-late/${id}`);
}

export async function getByDriverMonth(id, month) {
  return await api.get(`driver-late/${id}/${month}`);
}

export async function bulkUpload(file) {
  let formData = new FormData();
  formData.append("uploadedFile", file);
  return await api.post(`driver-invoice/bulk-upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function createLate(data) {
  return await api.post(`driver-late`, data);
}

export async function deleteLate(id) {
  return await api.delete(`driver-late/${id}`);
}
