import api from "@/api/api-config";

export async function getCollection() {
  return await api.get(`driver-bonus`);
}

export async function getByDriver(id) {
  return await api.get(`driver-bonus/${id}`);
}

export async function getByDriverMonth(id, month) {
  return await api.get(`driver-bonus/${id}/${month}`);
}

export async function bulkUpload(file) {
  let formData = new FormData();
  formData.append("uploadedFile", file);
  return await api.post(`driver-invoice/bulk-upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function createBonus(data) {
  return await api.post(`driver-bonus`, data);
}

export async function deleteBonus(id) {
  return await api.delete(`driver-bonus/${id}`);
}
