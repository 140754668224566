import * as types from "./types";
import {
  clearShift,
  getShifts,
  getShiftsRange,
  trackShift,
  updateShift,
} from "./services";

export async function getShiftsAction({ commit }, payload) {
  commit(types.PROCESSING_WORKING_SHIFT, true);

  try {
    const { data } = await getShifts(payload.date, payload.user);
    commit(types.GET_WORKING_SHIFT, data);
  } catch (e) {
    console.error(e);
  }

  commit(types.PROCESSING_WORKING_SHIFT, false);
}

export async function getShiftsRangeAction({ commit }, payload) {
  commit(types.PROCESSING_WORKING_SHIFT, true);

  let state = {
    success: false,
    message: null,
    data: [],
  };
  try {
    const { data } = await getShiftsRange(payload.date, payload.user);
    state.success = true;
    state.data = data;
    commit(types.GET_WORKING_SHIFT, data);
  } catch (e) {
    console.error(e);
  } finally {
    commit(types.PROCESSING_WORKING_SHIFT, false);
  }

  return state;
}

export async function trackDataAction({ commit }, payload) {
  commit(types.PROCESSING_WORKING_SHIFT, true);

  try {
    const { data } = await trackShift(payload);
    commit(types.ADD_WORKING_SHIFT, { shift: payload, id: data });
    return { success: 1, obj: data };
  } catch (e) {
    console.error(e);
  } finally {
    commit(types.PROCESSING_WORKING_SHIFT, false);
  }
}

export async function editDataAction({ commit }, payload) {
  commit(types.PROCESSING_WORKING_SHIFT, true);

  try {
    const { data } = await updateShift(payload);
    commit(types.UPDATE_WORKING_SHIFT, payload);
    return { success: 1, obj: data };
  } catch (e) {
    console.error(e);
  } finally {
    commit(types.PROCESSING_WORKING_SHIFT, false);
  }
}

export async function clearDataAction({ commit }, payload) {
  commit(types.PROCESSING_WORKING_SHIFT, true);

  try {
    const { data } = await clearShift(payload.id);
    commit(types.DELETE_WORKING_SHIFT, payload);
    return { success: 1, obj: data };
  } catch (e) {
    console.error(e);
  } finally {
    commit(types.PROCESSING_WORKING_SHIFT, false);
  }
}
