import * as types from "./types";

const mutations = {
  [types.PROCESSING_DRIVER_REPORT](state, value) {
    state.processing = value;
  },
  [types.GET_MONTHLY_REPORT](state, value) {
    state.data = {
      salaryForecast: 0,
      deliveriesCount: 0,
      servicesCount: 0,
    };
    if (value) {
      state.data = value;
    }
  },
};

export default mutations;
