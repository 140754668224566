import * as types from "./types";
import {
  getCoupons,
  getUserCoupons,
  createCoupon,
  deleteCoupon,
  getCoupon,
} from "./services";

export async function getCouponsAction({ commit }) {
  commit(types.PROCESSING_COUPONS, true);

  try {
    const { data } = await getCoupons();
    commit(types.GET_COUPONS_DATA, data);
  } catch (e) {
    console.error(e);
  }

  commit(types.PROCESSING_COUPONS, false);
}

export async function getUserCouponsAction({ commit }, payload) {
  commit(types.PROCESSING_COUPONS, true);

  try {
    const { data } = await getUserCoupons(payload);
    commit(types.GET_COUPONS_DATA, data);
  } catch (e) {
    console.error(e);
  }

  commit(types.PROCESSING_COUPONS, false);
}

export async function createCouponAction({ commit }, payload) {
  commit(types.PROCESSING_COUPONS, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await createCoupon(payload);
    commit(types.CREATE_COUPON, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_COUPONS, false);
  }

  return state;
}

export async function deleteCouponAction({ commit }, payload) {
  commit(types.PROCESSING_COUPONS, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await deleteCoupon(payload);
    commit(types.DELETE_COUPON, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_COUPONS, false);
  }

  return state;
}

export async function downloadCouponAction({ commit }, payload) {
  commit(types.PROCESSING_COUPONS, true);

  let state = {
    success: false,
    message: null,
    data: null,
  };
  try {
    const { data } = await getCoupon(payload.userId, payload.couponId);
    state.data = data;
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_COUPONS, false);
  }

  return state;
}
