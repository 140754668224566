import api from "@/api/api-config";

export async function getClaims() {
  return await api.get(`claim`);
}

export async function getUserClaims(id) {
  return await api.get(`claim/user/${id}`);
}

export async function createClaim(data) {
  return await api.post(`claim`, data);
}

export async function deleteClaim(id) {
  return await api.delete(`claim/${id}`);
}

export async function getClaim(claimId) {
  return await api.get(`claim/${claimId}`);
}

export async function getShiftClaim(shiftId) {
  return await api.get(`claim/shift/${shiftId}`);
}

export async function fixShiftClaim(claimId) {
  return await api.put(`claim/done/${claimId}`);
}

export async function changeClaimStatus(data) {
  return await api.put(`claim/${data.claimId}/status/${data.statusId}`);
}

export async function addClaimComment(data) {
  return await api.post(`claim/comment`, data);
}

export async function deleteClaimComment(id) {
  return await api.delete(`claim/comment/${id}`);
}
