const state = {
  common: {
    regions: [],
    shiftTypes: [],
    date: null,
    bannedDates: [],
  },
  loading: false,
};

export default state;
