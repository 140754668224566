import * as types from "./types";

const mutations = {
  [types.GET_COMMON_DATA](state, value) {
    state.common = value;
  },
  [types.LOADING_COMMON_DATA](state, value) {
    state.loading = value;
  },
};

export default mutations;
