import * as types from "./types";
import {
  getBaseReport,
  getShiftReport,
  getDetailedShiftReport,
  downloadShiftReport,
  downloadDetailedShiftReport,
} from "./services";

export async function getBaseReportAction({ commit }, payload) {
  commit(types.PROCESSING_MANAGER_REPORT, true);

  let state = {
    success: false,
    message: null,
    data: {},
  };
  try {
    const { data } = await getBaseReport(payload);
    state.data = data;
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_MANAGER_REPORT, false);
  }

  return state;
}

export async function getShiftReportAction({ commit }, payload) {
  commit(types.PROCESSING_MANAGER_REPORT, true);

  let state = {
    success: false,
    message: null,
    data: {},
  };
  try {
    const { data } = await getShiftReport(payload);
    state.data = data;
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_MANAGER_REPORT, false);
  }

  return state;
}

export async function downloadShiftReportAction({ commit }, payload) {
  commit(types.PROCESSING_MANAGER_REPORT, true);

  let state = {
    success: false,
    message: null,
    data: null,
  };
  try {
    const { data } = await downloadShiftReport(
      payload.daily,
      payload.month,
      payload.year,
      payload.list
    );
    state.data = data;
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_MANAGER_REPORT, false);
  }

  return state;
}

export async function getDetailedShiftReportAction({ commit }) {
  commit(types.PROCESSING_MANAGER_REPORT, true);

  let state = {
    success: false,
    message: null,
    data: {},
  };
  try {
    const { data } = await getDetailedShiftReport();
    state.data = data;
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_MANAGER_REPORT, false);
  }

  return state;
}

export async function downloadDetailedShiftReportAction({ commit }, payload) {
  commit(types.PROCESSING_MANAGER_REPORT, true);

  let state = {
    success: false,
    message: null,
    data: null,
  };
  try {
    const { data } = await downloadDetailedShiftReport(
      payload.month,
      payload.year,
      payload.list
    );
    state.data = data;
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_MANAGER_REPORT, false);
  }

  return state;
}
