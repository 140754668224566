import * as types from "./types";

const mutations = {
  [types.PROCESSING_STUDENTS](state, value) {
    state.processing = value;
  },
  [types.PROCESSING_STUDENT](state, value) {
    state.processingStudent = value;
  },
  [types.GET_STUDENTS_DATA](state, value) {
    state.students = value;
  },
  [types.GET_STUDENT_DATA](state, value) {
    state.student = value;
  },
  [types.CREATE_STUDENT](state, value) {
    state.student = value;
    state.students.push(value);
  },
  [types.UPDATE_STUDENT](state, value) {
    state.student = value;
    let idx = state.students.findIndex((el) => el.id === value.id);
    if (idx !== -1) {
      state.students[idx].id = value.id;
      state.students[idx].firstName = value.firstName;
      state.students[idx].lastName = value.lastName;
      state.students[idx].fullName = value.fullName;
      state.students[idx].regionId = value.regionId;
      state.students[idx].regionName = value.regionName;
      state.students[idx].trainings = value.trainings;
    }
  },
  [types.DELETE_STUDENT](state, value) {
    state.student = null;
    let idx = state.students.findIndex((el) => el.id === value);
    if (idx !== -1) {
      state.students.splice(idx, 1);
    }
  },
};

export default mutations;
