import * as types from "./types";

const mutations = {
  [types.PROCESSING_WORKING_SCHEDULE](state, value) {
    state.processing = value;
  },
  [types.GET_FILLED_SHIFTS](state, value) {
    state.filled = value;
  },
};

export default mutations;
