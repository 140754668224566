import * as types from "./types";
import jwt_decode from "jwt-decode";

const mutations = {
  [types.LOGIN_USER](state, token) {
    state.signInState.token = token;
    const loginClaim = jwt_decode(token);
    claimToState(state, loginClaim);
    localStorage.setItem("token", token);
  },
  [types.LOCAL_STORAGE_TOKEN_LOGIN](state, token) {
    state.signInState.token = token;
    const loginClaim = jwt_decode(token);
    claimToState(state, loginClaim);
  },
};

export default mutations;

function claimToState(state, claim) {
  state.signInState.sub = parseInt(claim.sub);
  state.signInState.email = claim.email;
  state.signInState.name = claim.name;
  state.signInState.region = parseInt(claim.region);
  state.signInState.ppd = parseFloat(claim.ppd);
  if (claim.pps) state.signInState.pps = parseFloat(claim.pps);
  if (claim.ppe) state.signInState.ppe = parseFloat(claim.ppe);
  if (claim.ppl) state.signInState.ppl = parseFloat(claim.ppl);
  if (claim.ppc) state.signInState.ppc = parseFloat(claim.ppc);
  if (claim.ppt) state.signInState.ppt = parseFloat(claim.ppt);
  if (claim.ppsh) state.signInState.ppsh = parseFloat(claim.ppsh);
  state.signInState.exp = claim.exp;
  state.signInState.roles = claim.roles.split("|");
}
