import api from "@/api/api-config";
import jwt_decode from "jwt-decode";

export async function loginUser(login) {
  return await api.post("token", login);
}

export async function validateToken(token) {
  return await api.get(`token/${token}`);
}

const key = "token";

export function getToken() {
  return localStorage.getItem(key);
}

export function logOut() {
  localStorage.clear();
  window.location = "/login";
}

export async function isTokenFromLocalStorageValid() {
  const token = localStorage.getItem(key);
  if (!token) {
    return false;
  }

  const { data } = await validateToken(token);
  if (!data.isValid) {
    return false;
  }

  const decoded = jwt_decode(token);
  const expiresAt = decoded.exp * 1000;
  const dateNow = Date.now();
  return dateNow < expiresAt;
}

export function getUserEmailFromToken() {
  const token = localStorage.getItem(key);
  if (!token) return false;
  const decoded = jwt_decode(token);
  return decoded.email;
}

export function isDriver() {
  const token = localStorage.getItem(key);
  if (!token) return false;
  const decoded = jwt_decode(token);
  return decoded.roles && decoded.roles.indexOf("Driver") !== -1;
}

export function isTrainer() {
  const token = localStorage.getItem(key);
  if (!token) return false;
  const decoded = jwt_decode(token);
  return decoded.roles && decoded.roles.indexOf("Trainer") !== -1;
}

export function isAdmin() {
  const token = localStorage.getItem(key);
  if (!token) return false;
  const decoded = jwt_decode(token);
  return (
    decoded.roles &&
    (decoded.roles.indexOf("Admin") !== -1 ||
      decoded.roles.indexOf("Supervisor") !== -1 ||
      decoded.roles.indexOf("Dev") !== -1)
  );
}

export function isSupervisor() {
  const token = localStorage.getItem(key);
  if (!token) return false;
  const decoded = jwt_decode(token);
  return (
    decoded.roles &&
    (decoded.roles.indexOf("Supervisor") !== -1 ||
      decoded.roles.indexOf("Dev") !== -1)
  );
}
