import * as types from "./types";
import {
  getStudents,
  getStudent,
  createStudent,
  updateStudent,
  deleteStudent,
} from "./services";

export async function getStudentsAction({ commit }) {
  commit(types.PROCESSING_STUDENTS, true);

  try {
    const { data } = await getStudents();
    commit(types.GET_STUDENTS_DATA, data);
  } catch (e) {
    console.error(e);
  }

  commit(types.PROCESSING_STUDENTS, false);
}

export async function getStudentAction({ commit }, payload) {
  commit(types.PROCESSING_STUDENT, true);

  try {
    const { data } = await getStudent(payload);
    commit(types.GET_STUDENT_DATA, data);
  } catch (e) {
    console.error(e);
  }

  commit(types.PROCESSING_STUDENT, false);
}

export async function createStudentAction({ commit }, payload) {
  commit(types.PROCESSING_STUDENT, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await createStudent(payload);
    commit(types.CREATE_STUDENT, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_STUDENT, false);
  }

  return state;
}

export async function updateStudentAction({ commit }, payload) {
  commit(types.PROCESSING_STUDENT, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await updateStudent(payload);
    commit(types.UPDATE_STUDENT, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_STUDENT, false);
  }

  return state;
}

export async function deleteStudentAction({ commit }, payload) {
  commit(types.PROCESSING_STUDENT, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await deleteStudent(payload);
    commit(types.DELETE_STUDENT, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_STUDENT, false);
  }

  return state;
}
