import * as types from "./types";
import { getMonthlyReport } from "./services";

export async function getMonthlyReportAction({ commit }, payload) {
  commit(types.PROCESSING_DRIVER_REPORT, true);

  try {
    const { data } = await getMonthlyReport(payload);
    commit(types.GET_MONTHLY_REPORT, data);
  } catch (e) {
    console.error(e);
  }

  commit(types.PROCESSING_DRIVER_REPORT, false);
}
