import api from "@/api/api-config";

export async function getBaseReport(daily) {
  return await api.get(`manager/report/${daily}`);
}

export async function getShiftReport(daily) {
  return await api.get(`manager/report/shift/${daily}`);
}

export async function downloadShiftReport(daily, month, year, list) {
  return await api.post(
    `manager/report/download/shift/${daily}/${month}/${year}`,
    list,
    {
      responseType: "blob",
    }
  );
}

export async function getDetailedShiftReport() {
  return await api.get(`manager/report/shift-detailed`);
}

export async function downloadDetailedShiftReport(month, year, list) {
  return await api.post(
    `manager/report/download/shift-detailed/${month}/${year}`,
    list,
    {
      responseType: "blob",
    }
  );
}
