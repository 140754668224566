import * as types from "./types";
import { getData } from "./services";

export async function getCommonDataAction({ commit }) {
  commit(types.LOADING_COMMON_DATA, true);

  try {
    const { data } = await getData();
    commit(types.GET_COMMON_DATA, data);
  } catch (e) {
    console.error("Troubles during loading common data...");
  }

  commit(types.LOADING_COMMON_DATA, false);
}
