import api from "@/api/api-config";

export async function getCollection() {
  return await api.get(`driver-penalty`);
}

export async function getByDriver(id) {
  return await api.get(`driver-penalty/${id}`);
}

export async function getByDriverMonth(id, month) {
  return await api.get(`driver-penalty/${id}/${month}`);
}

export async function bulkUpload(file) {
  let formData = new FormData();
  formData.append("uploadedFile", file);
  return await api.post(`driver-invoice/bulk-upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function createPenalty(data) {
  return await api.post(`driver-penalty`, data);
}

export async function deletePenalty(id) {
  return await api.delete(`driver-penalty/${id}`);
}
