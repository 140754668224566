import * as types from "./types";

const mutations = {
  [types.PROCESSING_DRIVER_PENALTY](state, value) {
    state.processing = value;
  },
  [types.PROCESSING_BULK_DRIVER_PENALTY](state, value) {
    state.bulkProcessing = value;
  },
  [types.GET_DRIVER_PENALTY](state, value) {
    state.penalties = value;
  },
  [types.CREATE_DRIVER_PENALTY](state, value) {
    state.penalties.push(value);
  },
  [types.DELETE_DRIVER_PENALTY](state, value) {
    let idx = state.penalties.findIndex((el) => el.id === value);
    if (idx !== -1) {
      state.penalties.splice(idx, 1);
    }
  },
};

export default mutations;
