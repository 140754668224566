import * as types from "./types";
import {
  getByDriver,
  getByDriverMonth,
  bulkUpload,
  createBonus,
  deleteBonus,
} from "./services";

export async function getByDriverAction({ commit }, payload) {
  commit(types.PROCESSING_DRIVER_BONUS, true);

  let state = {
    success: false,
    message: null,
    data: null,
  };

  try {
    const { data } = await getByDriver(payload);
    commit(types.GET_DRIVER_BONUS, data);
    state.success = true;
    state.data = data;
  } catch (e) {
    console.error(e);
    state.message = e.error;
  } finally {
    commit(types.PROCESSING_DRIVER_BONUS, false);
  }

  return state;
}

export async function getByDriverMonthAction({ commit }, payload) {
  commit(types.PROCESSING_DRIVER_BONUS, true);

  let state = {
    success: false,
    message: null,
    data: null,
  };

  try {
    const { data } = await getByDriverMonth(payload.id, payload.month);
    commit(types.GET_DRIVER_BONUS, data);
    state.success = true;
    state.data = data;
  } catch (e) {
    console.error(e);
    state.message = e.error;
  } finally {
    commit(types.PROCESSING_DRIVER_BONUS, false);
  }

  return state;
}

export async function bulkUploadAction({ commit }, payload) {
  commit(types.PROCESSING_BULK_DRIVER_INVOICE, true);

  let state = {
    success: false,
    message: null,
    data: null,
  };

  try {
    const { data } = await bulkUpload(payload);
    commit(types.DRIVER_INVOICES, data);
    state.success = true;
    state.data = data;
  } catch (e) {
    console.error(e);
    state.message = e.error;
  } finally {
    commit(types.PROCESSING_BULK_DRIVER_INVOICE, false);
  }

  return state;
}

export async function createBonusAction({ commit }, payload) {
  commit(types.PROCESSING_DRIVER_BONUS, true);

  let state = {
    success: false,
    data: null,
    message: null,
  };
  try {
    const { data } = await createBonus(payload);
    commit(types.CREATE_DRIVER_BONUS, data);
    state.data = data;
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_DRIVER_BONUS, false);
  }

  return state;
}

export async function deleteBonusAction({ commit }, payload) {
  commit(types.PROCESSING_DRIVER_BONUS, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await deleteBonus(payload.id);
    commit(types.DELETE_DRIVER_BONUS, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_DRIVER_BONUS, false);
  }

  return state;
}
