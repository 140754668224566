import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

import { authGuard } from "@/store/auth/auth.guard";
import {
  isTokenFromLocalStorageValid,
  isDriver,
  isAdmin,
  isSupervisor,
} from "@/auth/auth.service";

Vue.use(VueRouter);

const routes = [
  {
    name: "login",
    path: "/login",
    component: () => import("@/auth/views/LoginView.vue"),
    meta: {
      authRequired: false,
    },
    beforeEnter: async (to, from, next) => {
      const valid = await isTokenFromLocalStorageValid();
      if (valid) {
        next("/continue-as");
      } else {
        next();
      }
    },
  },
  {
    path: "/continue-as",
    component: () => import("@/auth/views/ContinueAs.vue"),
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      authRequired: false,
    },
    beforeEnter: (to, from, next) => {
      if (isDriver()) {
        next("/gabinet-osobisty");
      } else if (isSupervisor()) {
        next("/admin/");
      } else if (isAdmin()) {
        next("/admin/");
      } else {
        next();
      }
    },
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/gabinet-osobisty",
    name: "driverView",
    component: () => import("@/views/DriverView.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/logout",
    beforeEnter() {
      localStorage.clear();
      window.location.href = "/";
    },
  },
  {
    path: "/admin/",
    component: () => import("@/views/AdminPanel"),
    meta: {
      authRequired: true,
      authorize: ["Admin", "Dev"],
    },
    beforeEnter: async (to, from, next) => {
      const valid = isAdmin();
      if (valid) {
        next();
      } else {
        next("/");
      }
    },
    children: [
      {
        path: "",
        component: () => import("@/views/AdminPanel/DriverList"),
      },
      {
        path: "szkolenie",
        component: () => import("@/views/AdminPanel/StudentList"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "report/financial",
        component: () => import("@/views/AdminPanel/ReportFinancial"),
        meta: {
          authRequired: true,
          authorize: ["Dev"],
        },
      },
      {
        path: "report/driver-shifts-details",
        component: () => import("@/views/AdminPanel/ReportDriverShifts"),
        meta: {
          authRequired: true,
          authorize: ["Admin"],
        },
      },
      {
        path: "report/driver-financial",
        component: () => import("@/views/AdminPanel/ReportDriverFinancial"),
        meta: {
          authRequired: true,
          authorize: ["Dev"],
        },
      },
      {
        path: "driver-claims",
        component: () => import("@/views/AdminPanel/DriverClaims"),
        meta: {
          authRequired: true,
          authorize: ["Dev", "Admin"],
        },
      },
      {
        path: "driver-invoices",
        component: () => import("@/views/AdminPanel/DriverInvoices"),
        meta: {
          authRequired: true,
          authorize: ["Dev", "Admin"],
        },
      },
    ],
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  await authGuard(to, from, next);
});

export default router;
