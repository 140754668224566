import api from "@/api/api-config";

export async function getSchedule(userId, month) {
  return await api.get(`working-schedule/${userId}/${month}`);
}

export async function getFilledSchedule(regionId, month) {
  return await api.get(`working-schedule/filled/${regionId}/${month}`);
}

export async function setSchedule(item) {
  return await api.post(`working-schedule/set`, item);
}

export async function setCompletedSchedule(userId, month) {
  return await api.put(`working-schedule/${userId}/${month}`);
}

export async function downloadSchedule(list, month, year) {
  return await api.post(`working-schedule/download/${month}/${year}`, list, {
    responseType: "blob",
  });
}
