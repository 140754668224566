import * as types from "./types";
import {
  getUsers,
  getUser,
  createUser,
  updateUser,
  deleteUser,
} from "./services";

export async function getUsersAction({ commit }) {
  commit(types.PROCESSING_USERS, true);

  try {
    const { data } = await getUsers();
    commit(types.GET_USERS_DATA, data);
  } catch (e) {
    console.error(e);
  }

  commit(types.PROCESSING_USERS, false);
}

export async function getUserAction({ commit }, payload) {
  commit(types.PROCESSING_USER, true);

  try {
    const { data } = await getUser(payload);
    commit(types.GET_USER_DATA, data);
  } catch (e) {
    console.error(e);
  }

  commit(types.PROCESSING_USER, false);
}

export async function createUserAction({ commit }, payload) {
  commit(types.PROCESSING_USER, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await createUser(payload);
    commit(types.CREATE_USER, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_USER, false);
  }

  return state;
}

export async function updateUserAction({ commit }, payload) {
  commit(types.PROCESSING_USER, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await updateUser(payload);
    commit(types.UPDATE_USER, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_USER, false);
  }

  return state;
}

export async function deleteUserAction({ commit }, payload) {
  commit(types.PROCESSING_USER, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await deleteUser(payload);
    commit(types.DELETE_USER, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_USER, false);
  }

  return state;
}
