<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="4">
        <div class="text-h1" align="center">Twoja Praca</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "HomeView",

  title: "TwojaPraca",

  computed: {
    ...mapGetters("authModlule", {
      email: "email",
    }),
    ...mapGetters("commonModule", {
      common: "common",
      loading: "loading",
    }),
  },

  async mounted() {
    await this.localStorageLogin();
    await this.getCommonDataAction();
  },

  methods: {
    ...mapActions("authModule", ["useLocalStorageTokenToSignInAction"]),
    ...mapActions("commonModule", ["getCommonDataAction"]),

    async localStorageLogin() {
      await this.useLocalStorageTokenToSignInAction();
    },
  },
};
</script>
