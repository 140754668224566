import * as types from "./types";

const mutations = {
  [types.PROCESSING_COUPONS](state, value) {
    state.processing = value;
  },
  [types.GET_COUPONS_DATA](state, value) {
    state.coupons = value;
  },
  [types.CREATE_COUPON](state, value) {
    state.coupons.push(value);
  },
  [types.DELETE_COUPON](state, value) {
    let idx = state.coupons.findIndex((el) => el.id === value);
    if (idx !== -1) {
      state.coupons.splice(idx, 1);
    }
  },
};

export default mutations;
