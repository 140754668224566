const getters = {
  sub: (state) => {
    return state.signInState.sub;
  },
  email: (state) => {
    return state.signInState.email;
  },
  name: (state) => {
    return state.signInState.name;
  },
  region: (state) => {
    return parseInt(state.signInState.region);
  },
  ppd: (state) => {
    return state.signInState.ppd;
  },
  pps: (state) => {
    return state.signInState.pps;
  },
  ppe: (state) => {
    return state.signInState.ppe;
  },
  ppl: (state) => {
    return state.signInState.ppl;
  },
  ppc: (state) => {
    return state.signInState.ppc;
  },
  ppt: (state) => {
    return state.signInState.ppt;
  },
  ppsh: (state) => {
    return state.signInState.ppsh;
  },
  sis: (state) => {
    return state.signInState;
  },
  isAuthenticated: (state) => {
    return state.signInState.token;
  },
  isDriver: (state) => {
    return state.signInState.roles.includes("Driver");
  },
  isTrainer: (state) => {
    return (
      state.signInState.roles && state.signInState.roles.includes("Trainer")
    );
  },
  isAdmin: (state) => {
    return (
      state.signInState.roles &&
      (state.signInState.roles.includes("Admin") ||
        state.signInState.roles.includes("Supervisor") ||
        state.signInState.roles.includes("Dev"))
    );
  },
  isSupervisor: (state) => {
    return (
      state.signInState.roles &&
      (state.signInState.roles.includes("Supervisor") ||
        state.signInState.roles.includes("Dev"))
    );
  },
  isDev: (state) => {
    return state.signInState.roles && state.signInState.roles.includes("Dev");
  },
};

export default getters;
