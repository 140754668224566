import Vue from "vue";
import Vuex from "vuex";

import authModule from "./auth";
import commonModule from "./common";
import workingShiftModule from "./working-shift";
import driverModule from "./driver";
import userModule from "./user";
import managerModule from "./manager";
import scheduleModule from "./working-schedule";
import studentModule from "./student";
import couponModule from "./coupon";
import claimModule from "./claim";
import driverInvoiceModule from "./driver-invoice";
import driverPenaltyModule from "./driver-penalty";
import driverBonusModule from "./driver-bonus";
import driverNPSModule from "./driver-nps";
import driverLateModule from "./driver-late";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    authModule,
    commonModule,
    workingShiftModule,
    driverModule,
    userModule,
    managerModule,
    scheduleModule,
    studentModule,
    couponModule,
    claimModule,
    driverInvoiceModule,
    driverPenaltyModule,
    driverBonusModule,
    driverNPSModule,
    driverLateModule,
  },
});
