import * as types from "./types";
import {
  getCollection,
  getByDriver,
  bulkUpload,
  setPaid,
  getShowInvoices,
} from "./services";

export async function getInvoicesAction({ commit }) {
  commit(types.PROCESSING_DRIVER_INVOICE, true);

  let state = {
    success: false,
    message: null,
    data: null,
  };

  try {
    const { data } = await getCollection();
    commit(types.DRIVER_INVOICES, data);
    state.success = true;
    state.data = data;
  } catch (e) {
    console.error(e);
    state.message = e.error;
  } finally {
    commit(types.PROCESSING_DRIVER_INVOICE, false);
  }

  return state;
}

export async function getByDriverAction({ commit }, payload) {
  commit(types.PROCESSING_DRIVER_INVOICE, true);

  let state = {
    success: false,
    message: null,
    data: null,
  };

  try {
    const { data } = await getByDriver(payload);
    commit(types.DRIVER_INVOICES, data);
    state.success = true;
    state.data = data;
  } catch (e) {
    console.error(e);
    state.message = e.error;
  } finally {
    commit(types.PROCESSING_DRIVER_INVOICE, false);
  }

  return state;
}

export async function bulkUploadAction({ commit }, payload) {
  commit(types.PROCESSING_BULK_DRIVER_INVOICE, true);

  let state = {
    success: false,
    message: null,
    data: null,
  };

  try {
    const { data } = await bulkUpload(payload);
    commit(types.DRIVER_INVOICES, data);
    state.success = true;
    state.data = data;
  } catch (e) {
    console.error(e);
    state.message = e.error;
  } finally {
    commit(types.PROCESSING_BULK_DRIVER_INVOICE, false);
  }

  return state;
}

export async function setPaidAction({ commit }, payload) {
  commit(types.PROCESSING_DRIVER_INVOICE, true);

  let state = {
    success: false,
    message: null,
    data: null,
  };

  try {
    const { data } = await setPaid(payload.userId, payload.invoiceId);
    state.success = true;
    state.data = data;
  } catch (e) {
    console.error(e);
    state.message = e.error;
  } finally {
    commit(types.PROCESSING_DRIVER_INVOICE, false);
  }

  return state;
}

export async function getShowInvoicesAction({ commit }, payload) {
  commit(types.PROCESSING_DRIVER_INVOICE, true);

  let state = {
    success: false,
    message: null,
    data: null,
  };

  try {
    const { data } = await getShowInvoices(payload);
    commit(types.SHOW_INVOICES, data);
    state.success = true;
    state.data = data;
  } catch (e) {
    console.error(e);
    state.message = e.error;
  } finally {
    commit(types.PROCESSING_DRIVER_INVOICE, false);
  }

  return state;
}
