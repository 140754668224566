import * as types from "./types";
import {
  loginUser,
  isTokenFromLocalStorageValid,
  getToken,
} from "@/auth/auth.service";

export async function loginUserAction({ commit }, payload) {
  try {
    const { data } = await loginUser(payload);
    commit(types.LOGIN_USER, data);
  } catch (e) {
    console.error("Wrong login or password!");
  }
}

export async function useLocalStorageTokenToSignInAction({ commit }) {
  const valid = await isTokenFromLocalStorageValid();
  if (!valid) {
    return;
  }

  const token = getToken();
  commit(types.LOCAL_STORAGE_TOKEN_LOGIN, token);
}
