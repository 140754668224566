import * as types from "./types";
import {
  getSchedule,
  getFilledSchedule,
  setSchedule,
  setCompletedSchedule,
  downloadSchedule,
} from "./services";

export async function getScheduleAction({ commit }, payload) {
  commit(types.PROCESSING_WORKING_SCHEDULE, true);

  let state = {
    success: false,
    message: null,
    data: [],
  };
  try {
    const { data } = await getSchedule(payload.userId, payload.month);
    state.success = true;
    state.data = data;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_WORKING_SCHEDULE, false);
  }

  return state;
}

export async function getFilledScheduleAction({ commit }, payload) {
  commit(types.PROCESSING_WORKING_SCHEDULE, true);

  let state = {
    success: false,
    message: null,
    data: [],
  };
  try {
    const { data } = await getFilledSchedule(payload.regionId, payload.month);
    state.success = true;
    state.data = data;
    commit(types.GET_FILLED_SHIFTS, data);
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_WORKING_SCHEDULE, false);
  }

  return state;
}

export async function setScheduleAction({ commit }, payload) {
  commit(types.PROCESSING_WORKING_SCHEDULE, true);

  let state = {
    success: false,
    message: null,
    data: [],
  };
  try {
    const { data } = await setSchedule(payload);
    state.success = true;
    state.data = data;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_WORKING_SCHEDULE, false);
  }

  return state;
}

export async function setCompletedScheduleAction({ commit }, payload) {
  commit(types.PROCESSING_WORKING_SCHEDULE, true);

  let state = {
    success: false,
    message: null,
    data: [],
  };
  try {
    const { data } = await setCompletedSchedule(payload.userId, payload.month);
    state.success = true;
    state.data = data;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_WORKING_SCHEDULE, false);
  }

  return state;
}

export async function downloadScheduleAction({ commit }, payload) {
  commit(types.PROCESSING_WORKING_SCHEDULE, true);

  let state = {
    success: false,
    message: null,
    data: null,
  };
  try {
    const { data } = await downloadSchedule(
      payload.list,
      payload.month,
      payload.year
    );
    state.success = true;
    state.data = data;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_WORKING_SCHEDULE, false);
  }

  return state;
}
