import * as types from "./types";

const mutations = {
  [types.PROCESSING_CLAIMS](state, value) {
    state.processing = value;
  },
  [types.GET_CLAIMS_DATA](state, value) {
    state.claim = value;
  },
  [types.CREATE_CLAIM](state, value) {
    state.claims.push(value);
  },
  [types.DELETE_CLAIM](state, value) {
    let idx = state.claims.findIndex((el) => el.id === value);
    if (idx !== -1) {
      state.claims.splice(idx, 1);
    }
  },
};

export default mutations;
