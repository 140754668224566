import api from "@/api/api-config";

export async function getShifts(date, userId) {
  return await api.get(`working-shift/${userId}/${date}`);
}

export async function getShiftsRange(dateFrom, userId) {
  return await api.get(`working-shift/${userId}/range/${dateFrom}`);
}

export async function trackShift(data) {
  return await api.post(`working-shift`, data);
}

export async function updateShift(data) {
  return await api.put(`working-shift`, data);
}

export async function clearShift(data) {
  return await api.delete(`working-shift/${data}`);
}
