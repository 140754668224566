import * as types from "./types";

const mutations = {
  [types.PROCESSING_DRIVER_INVOICE](state, value) {
    state.processing = value;
  },
  [types.PROCESSING_BULK_DRIVER_INVOICE](state, value) {
    state.bulkProcessing = value;
  },
  [types.DRIVER_INVOICES](state, value) {
    state.invoices = value;
  },
  [types.SHOW_INVOICES](state, value) {
    state.showInvoices = value;
  },
};

export default mutations;
