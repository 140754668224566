<template>
  <v-app>
    <NavigationBar />
    <v-main>
      <router-view />
      <v-dialog v-model="hashChanged" persistent width="auto">
        <v-card>
          <v-card-title class="text-h5 wb-normal">
            Dostępna nowa wersja aplikacji
          </v-card-title>
          <v-card-actions class="text-center">
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1 white--text"
              variant="text"
              @click="reloadApp"
            >
              Aktualizuj
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import NavigationBar from "@/components/NavigationBar";
import { refreshPageMixin } from "./mixins/refresh-page.mixin";

export default {
  name: "App",

  title: "TwojaPraca",

  components: {
    NavigationBar,
  },

  data() {
    return {};
  },

  mixins: [refreshPageMixin],

  mounted() {
    this.initVersionCheck();
  },

  methods: {
    isProduction() {
      console.log(process.env.NODE_ENV);
      return process.env.NODE_ENV === "production";
    },
  },
};
</script>

<style>
.wb-normal {
  word-break: normal;
}
</style>
