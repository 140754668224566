import * as types from "./types";

const mutations = {
  [types.PROCESSING_DRIVER_LATE](state, value) {
    state.processing = value;
  },
  [types.PROCESSING_BULK_DRIVER_LATE](state, value) {
    state.bulkProcessing = value;
  },
  [types.GET_DRIVER_LATE](state, value) {
    state.lates = value;
  },
  [types.CREATE_DRIVER_LATE](state, value) {
    state.lates.push(value);
  },
  [types.DELETE_DRIVER_LATE](state, value) {
    let idx = state.lates.findIndex((el) => el.id === value);
    if (idx !== -1) {
      state.lates.splice(idx, 1);
    }
  },
};

export default mutations;
