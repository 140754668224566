import api from "@/api/api-config";

export async function getCollection() {
  return await api.get(`driver-nps`);
}

export async function getByDriver(id) {
  return await api.get(`driver-nps/${id}`);
}

export async function getByDriverMonth(id, month) {
  return await api.get(`driver-nps/${id}/${month}`);
}

export async function bulkUpload(file) {
  let formData = new FormData();
  formData.append("uploadedFile", file);
  return await api.post(`driver-invoice/bulk-upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function createNPS(data) {
  return await api.post(`driver-nps`, data);
}

export async function deleteNPS(id) {
  return await api.delete(`driver-nps/${id}`);
}
