import * as types from "./types";

const mutations = {
  [types.PROCESSING_DRIVER_NPS](state, value) {
    state.processing = value;
  },
  [types.PROCESSING_BULK_DRIVER_NPS](state, value) {
    state.bulkProcessing = value;
  },
  [types.GET_DRIVER_NPS](state, value) {
    state.npses = value;
  },
  [types.CREATE_DRIVER_NPS](state, value) {
    state.npses.push(value);
  },
  [types.DELETE_DRIVER_NPS](state, value) {
    let idx = state.npses.findIndex((el) => el.id === value);
    if (idx !== -1) {
      state.npses.splice(idx, 1);
    }
  },
};

export default mutations;
