import api from "@/api/api-config";

export const refreshPageMixin = {
  data() {
    return {
      currentHash: localStorage.getItem("version"),
      hashChanged: false,
      newHash: "",
    };
  },

  methods: {
    initVersionCheck(frequency = 1000 * 60 * 5) {
      this.checkVersion();
      setInterval(() => {
        this.checkVersion();
      }, frequency);
    },

    async checkVersion() {
      try {
        const response = await api.get(`version`);
        this.newHash = response.data;

        let production = process.env.NODE_ENV === "production";
        this.hashChanged =
          this.hasHashChanged(this.currentHash, this.newHash) && production;
      } catch (error) {
        this.loading = false;
        if (!error.response) {
          this.errorStatus = "Error: Network Error";
        } else {
          this.errorStatus = error.response.data.message;
        }
      }
    },

    hasHashChanged(currentHash, newHash) {
      if (!currentHash) {
        return true;
      }

      return currentHash !== newHash;
    },

    reloadApp() {
      this.currentHash = this.newHash;
      localStorage.setItem("version", this.currentHash);
      window.location.reload();
    },
  },
};
