import * as types from "./types";

const mutations = {
  [types.PROCESSING_USERS](state, value) {
    state.processing = value;
  },
  [types.PROCESSING_USER](state, value) {
    state.processingUser = value;
  },
  [types.GET_USERS_DATA](state, value) {
    state.users = value;
  },
  [types.GET_USER_DATA](state, value) {
    state.user = value;
  },
  [types.CREATE_USER](state, value) {
    state.user = value;
    //state.users[value.id] = `${value.firstName} ${value.lastName}`;
  },
  [types.UPDATE_USER](state, value) {
    state.user = value;
    //state.users[value.id] = `${value.firstName} ${value.lastName}`;
  },
  [types.DELETE_USER](state) {
    state.user = null;
    //delete state.users[value.id];
  },
};

export default mutations;
