import api from "@/api/api-config";

export async function getStudents() {
  return await api.get(`student`);
}

export async function getStudent(id) {
  return await api.get(`student/${id}`);
}

export async function createStudent(data) {
  return await api.post(`student`, data);
}

export async function updateStudent(data) {
  return await api.put(`student`, data);
}

export async function deleteStudent(id) {
  return await api.delete(`student/${id}`);
}
