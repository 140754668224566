import api from "@/api/api-config";

export async function getCoupons() {
  return await api.get(`coupon`);
}

export async function getUserCoupons(id) {
  return await api.get(`coupon/${id}`);
}

export async function createCoupon(data) {
  return await api.post(`coupon`, data);
}

export async function deleteCoupon(id) {
  return await api.delete(`coupon/${id}`);
}

export async function getCoupon(userId, couponId) {
  return await api.get(`coupon/download/${userId}/${couponId}`, {
    responseType: "blob",
  });
}
