import * as types from "./types";

const mutations = {
  [types.PROCESSING_WORKING_SHIFT](state, value) {
    state.processing = value;
  },
  [types.GET_WORKING_SHIFT](state, value) {
    let key = Date.now();
    state.shifts = [
      { number: 1, disabled: false, key: key },
      { number: 2, disabled: false, key: ++key },
      { number: 3, disabled: false, key: ++key },
    ];
    if (value && value.length > 0) {
      for (let idx = 0; idx < value.length; idx++) {
        state.shifts[value[idx].number - 1] = value[idx];
        state.shifts[value[idx].number - 1].key = ++key;
      }
    }
  },
  [types.ADD_WORKING_SHIFT](state, value) {
    value.shift.id = value.id;
    state.shifts[value.shift.number - 1] = value.shift;
  },
  [types.UPDATE_WORKING_SHIFT](state, value) {
    state.shifts[value.number - 1] = value;
  },
  [types.DELETE_WORKING_SHIFT](state, value) {
    state.shifts[value - 1] = { number: value, disabled: false };
  },
};

export default mutations;
